/* Local dependencies */
import {
  VehicleArrivalPurpose,
  VehicleStatus,
} from '../../create-vehicle/vehicles_types';
import { DEFAULT_VEHICLES_FILTER } from '../redux/actions';

export const adminTabs = [
  {
    label: 'arrived',
    value: JSON.stringify(DEFAULT_VEHICLES_FILTER),
  },
  {
    label: 'withCargo',
    value: JSON.stringify({
      arrivalPurpose: [VehicleArrivalPurpose.CARGO_IMPORT],
      status: [VehicleStatus.ARRIVED],
    }),
  },
  {
    label: 'withoutCargo',
    value: JSON.stringify({
      arrivalPurpose: [VehicleArrivalPurpose.CARGO_EXTRACTION],
      status: [VehicleStatus.ARRIVED],
    }),
  },
  {
    label: 'archive',
    value: JSON.stringify({
      status: [VehicleStatus.DEPARTED],
    }),
  },
];

export const managerOperatorTabs = [
  {
    label: 'arrived',
    value: JSON.stringify(DEFAULT_VEHICLES_FILTER),
  },
  {
    label: 'withCargo',
    value: JSON.stringify({
      arrivalPurpose: [VehicleArrivalPurpose.CARGO_IMPORT],
      status: [VehicleStatus.ARRIVED],
    }),
  },
  {
    label: 'withoutCargo',
    value: JSON.stringify({
      arrivalPurpose: [VehicleArrivalPurpose.CARGO_EXTRACTION],
      status: [VehicleStatus.ARRIVED],
    }),
  },
];
