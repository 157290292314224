/* External dependencies */
import { Col, Icon, Row, Text } from 'atomize';
import { FormattedMessage, navigate } from 'gatsby-plugin-react-intl';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import PrimaryButton from '../buttons/PrimaryButton';
import { getCargo } from '../cargo-detail/redux/actions';
import DateTime from '../common/DateTime';
import {
  getUserRole,
  isAdmin,
  isManagerOperator,
  setLocalStorage,
} from '../common/helpers';
import { UserRoles } from '../common/roles';
import { listConsigneeGoodsSpecificationsRequest } from '../goods-specifications/listConsigneeGoodsSpecifications/redux/actions';
import Search from '../search/Search';
import Table from '../tables/Table';
import Tabs from '../tabs/Tabs';
import { Vehicle } from '../vehicle-details/redux/actions';
import { adminTabs, managerOperatorTabs } from './common/tabs-items';
import { SortByField, SortDirection, listVehicles } from './redux/actions';
import { VehiclesState } from './redux/reducer';

export default function VehiclesComponent() {
  const dispatch = useDispatch();
  // `formatMessage` is a hook, therefore must always run unconditionally.
  const findVehicle = formatMessage('findVehicle');

  const { currentPage, loading, searchString, total, vehicles, filter, sort } =
    useSelector((state: RootState): VehiclesState => state.vehicles);

  const { currentUser } = useSelector((state: RootState) => state.login);

  useEffect(() => {
    dispatch(listVehicles());
  }, []);

  function onSearch(e) {
    dispatch(listVehicles(e.target.value, 1, filter, sort));
  }

  function resetSearchField() {
    onSearch({
      target: {
        value: '',
      },
    });
  }

  function onPageChange(page) {
    dispatch(listConsigneeGoodsSpecificationsRequest(searchString, page));
  }
  function onFilterChange(e) {
    const filter = JSON.parse(e.target.value);

    dispatch(listVehicles('', 1, filter, sort));
  }

  function renderVehicleNumber({ id, vehiclePlateNumber }: Vehicle) {
    const onClickVehiclePlateNumber = () => {
      setLocalStorage('vehicleId', id);

      if (isManagerOperator(currentUser) || isAdmin(currentUser)) {
        // Vehicle and its single cargo has the same ID.
        dispatch(getCargo(id));
      }

      navigate('/vehicles/vehicle-details');
    };

    return (
      <p
        style={{ color: 'inherit', cursor: 'pointer' }}
        onClick={onClickVehiclePlateNumber}
      >
        {vehiclePlateNumber}
      </p>
    );
  }

  const currentTab = JSON.stringify(filter);

  const onSortVehicles = () => {
    const sortQuery = {
      field: SortByField.ARRIVALDATE,
      direction:
        sort.direction === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC,
    };

    dispatch(listVehicles('', 1, filter, sortQuery));
  };

  const topBar = (
    <>
      <Text
        textColor="table_cell_text_color"
        textSize={{ xs: 'mobileTitle', md: 'title' }}
        textWeight="700"
      >
        <FormattedMessage id="Vehicles" /> (
        {loading ? (
          <Icon cursor="pointer" m="0 .62rem" name="Loading3" size="20px" />
        ) : (
          total
        )}
        )
      </Text>
    </>
  );

  return (
    <Table
      currentPage={currentPage}
      columns={getFields()}
      controlBar={getControlBar()}
      className="table"
      emptyMessage={<FormattedMessage id="noVehicles" />}
      items={vehicles.map((vehicle: Vehicle) => ({
        ...vehicle,
        vehiclePlateNumber: renderVehicleNumber(vehicle),
      }))}
      loading={loading}
      onPageChange={onPageChange}
      searchString={searchString}
      stickyCol={getStickyCol()}
      topBar={topBar}
      totalItemsCount={total}
    />
  );

  function getStickyCol() {
    const userRole = getUserRole(currentUser);

    switch (userRole) {
      case UserRoles.BTS_ADMIN:
        return 2;
      case UserRoles.BTS_MANAGER_OPERATOR:
        return 1;
      // Weighman should have the same view as the operator.
      case UserRoles.BTS_OPERATOR:
        return 1;
      case UserRoles.BTS_WEIGHMAN:
        return 1;
      case UserRoles.BTS_OPERATOR_WEIGHMAN:
        return 1;
      default:
        throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
    }
  }

  function getControlBar() {
    const userRole = getUserRole(currentUser);

    switch (userRole) {
      case UserRoles.BTS_ADMIN:
        return getAdminControlBar();
      case UserRoles.BTS_MANAGER_OPERATOR:
        return getManagerOperatorControlBar();
      // Weighman should have the same view as the operator.
      case UserRoles.BTS_OPERATOR:
        return getOperatorControlBar();
      case UserRoles.BTS_WEIGHMAN:
        return getWeighmanControlBar();
      case UserRoles.BTS_OPERATOR_WEIGHMAN:
        return getOperatorWeighmanControlBar();
      default:
        throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
    }
  }

  function getOperatorControlBar() {
    return (
      <>
        <Row
          m={{ y: '.5rem', x: '-.5rem' }}
          justify="space-between"
          align={{ xs: 'flex-start', md: 'center' }}
        >
          <Col size={{ xs: '12', md: '6' }} style={{ marginBottom: '1rem' }}>
            <Search
              onChange={onSearch}
              onClear={resetSearchField}
              placeholder={findVehicle}
              value={searchString}
            />
          </Col>
          <Col
            size={{ xs: '12', md: '6' }}
            d={{ xs: 'block', md: 'flex' }}
            justify={{ md: 'flex-end' }}
            style={{ marginBottom: '1rem' }}
          >
            <PrimaryButton
              onClick={() => navigate('/vehicles/create')}
              icon="Plus"
            >
              <FormattedMessage id="registerEntry" />
            </PrimaryButton>
          </Col>
        </Row>
      </>
    );
  }

  function getOperatorWeighmanControlBar() {
    return (
      <Row
        m={{ y: '.5rem', x: '-.5rem' }}
        justify="space-between"
        align={{ xs: 'flex-start', md: 'center' }}
      >
        <Col size={{ xs: '12', md: '6' }} style={{ marginBottom: '1rem' }}>
          <Search
            onChange={onSearch}
            onClear={resetSearchField}
            placeholder={findVehicle}
            value={searchString}
          />
        </Col>
        <Col
          size={{ xs: '12', md: '6' }}
          d={{ xs: 'block', md: 'flex' }}
          justify={{ md: 'flex-end' }}
          style={{ marginBottom: '1rem' }}
        >
          <PrimaryButton
            onClick={() => navigate('/vehicles/create')}
            icon="Plus"
          >
            <FormattedMessage id="registerEntry" />
          </PrimaryButton>
        </Col>
      </Row>
    );
  }

  function getWeighmanControlBar() {
    return (
      <>
        <Row
          m={{ y: '.5rem', x: '-.5rem' }}
          justify="space-between"
          align={{ xs: 'flex-start', md: 'center' }}
        >
          <Col size={{ xs: '12', md: '6' }} style={{ marginBottom: '1rem' }}>
            <Search
              onChange={onSearch}
              onClear={resetSearchField}
              placeholder={findVehicle}
              value={searchString}
            />
          </Col>
        </Row>
      </>
    );
  }

  function getManagerOperatorControlBar() {
    return (
      <Row
        m={{ y: '.5rem', x: '-.5rem' }}
        justify="space-between"
        align={{ xs: 'flex-start', md: 'space-between' }}
      >
        <Col size={{ xs: '12', md: '6' }}>
          <Tabs
            inputName=""
            items={managerOperatorTabs}
            onClick={onFilterChange}
            value={currentTab}
          />
        </Col>
        <Col
          size={{ xs: '12', md: '6' }}
          d={{ xs: 'block', md: 'flex' }}
          justify={{ md: 'flex-end' }}
          style={{ marginBottom: '1rem' }}
        >
          <Search
            onChange={onSearch}
            onClear={resetSearchField}
            placeholder={findVehicle}
            value={searchString}
          />
        </Col>
      </Row>
    );
  }

  function getAdminControlBar() {
    return (
      <Row
        m={{ y: '.5rem', x: '-.5rem' }}
        justify="space-between"
        align={{ xs: 'flex-start', lg: 'center' }}
      >
        <Col size={{ xs: '12', lg: '6' }}>
          <Tabs
            inputName=""
            items={adminTabs}
            onClick={onFilterChange}
            value={currentTab}
          />
        </Col>
        <Col
          size={{ xs: '12', lg: '6' }}
          d={{ xs: 'block', lg: 'flex' }}
          justify={{ lg: 'flex-end' }}
          style={{ marginBottom: '1rem' }}
        >
          <Search
            onChange={onSearch}
            onClear={resetSearchField}
            placeholder={findVehicle}
            value={searchString}
          />
        </Col>
      </Row>
    );
  }

  function getFields() {
    const userRole = getUserRole(currentUser);

    switch (userRole) {
      case UserRoles.BTS_ADMIN:
        return getAdminFields();

      case UserRoles.BTS_CASHIER:
        return getOperatorFields();

      case UserRoles.BTS_OPERATOR:
        return getOperatorFields();

      case UserRoles.BTS_OPERATOR_WEIGHMAN:
        return getOperatorWeighmanFields();

      case UserRoles.BTS_MANAGER_OPERATOR:
        return getManagerOperatorFields();

      case UserRoles.BTS_WEIGHMAN:
        return getWeighmanFields();

      default:
        throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
    }
  }

  function getOperatorFields() {
    return [
      {
        field: (vehicle: Vehicle) => <DateTime date={vehicle.arrivalDate} />,
        title: <FormattedMessage id="arrivalDate" />,
        icon: (
          <Icon
            onClick={onSortVehicles}
            name={sort && sort.direction === 'ASC' ? 'UpArrow' : 'DownArrow'}
            size="20px"
            style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          />
        ),
      },
      {
        field: 'vehiclePlateNumber',
        title: <FormattedMessage id="Vehicle" />,
      },
      {
        field: 'trailerPlateNumber',
        title: <FormattedMessage id="Trailer" />,
      },
      {
        field: (vehicle: Vehicle) => (
          <FormattedMessage id={vehicle.arrivalPurpose} />
        ),
        title: <FormattedMessage id="Purpose" />,
      },
    ];
  }

  function getManagerOperatorFields() {
    return [
      {
        field: (vehicle: Vehicle) => <DateTime date={vehicle.arrivalDate} />,
        title: <FormattedMessage id="arrivalDate" />,
        icon: (
          <Icon
            onClick={onSortVehicles}
            name={sort && sort.direction === 'ASC' ? 'UpArrow' : 'DownArrow'}
            size="20px"
            style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          />
        ),
      },
      {
        field: 'vehiclePlateNumber',
        title: <FormattedMessage id="Vehicle" />,
      },
      {
        field: 'trailerPlateNumber',
        title: <FormattedMessage id="Trailer" />,
      },
      {
        field: 'cargoWeight',
        title: <FormattedMessage id="cargoWeight" />,
      },
      {
        field: 'slots ',
        title: <FormattedMessage id="slots" />,
      },
      {
        field: (vehicle: Vehicle) => (
          <FormattedMessage id={vehicle.arrivalPurpose} />
        ),
        title: <FormattedMessage id="Purpose" />,
      },
      {
        field: 'departureCountry',
        title: <FormattedMessage id="where" />,
      },
    ];
  }

  function getAdminFields() {
    return [
      {
        field: (vehicle: Vehicle) => <DateTime date={vehicle.arrivalDate} />,
        title: <FormattedMessage id="arrivalDate" />,
        icon: (
          <Icon
            onClick={onSortVehicles}
            name={sort && sort.direction === 'ASC' ? 'UpArrow' : 'DownArrow'}
            size="20px"
            style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          />
        ),
      },
      {
        field: 'departureDate',
        title: <FormattedMessage id="departureDate" />,
      },
      {
        field: 'vehiclePlateNumber',
        title: <FormattedMessage id="Vehicle" />,
      },
      {
        field: 'trailerPlateNumber',
        title: <FormattedMessage id="Trailer" />,
      },
      {
        field: 'cargoWeight',
        title: <FormattedMessage id="cargoWeight" />,
      },
      {
        field: 'slots ',
        title: <FormattedMessage id="slots" />,
      },
      {
        field: (vehicle: Vehicle) => (
          <FormattedMessage id={vehicle.arrivalPurpose} />
        ),
        title: <FormattedMessage id="Purpose" />,
      },
      {
        field: 'departureCountry',
        title: <FormattedMessage id="where" />,
      },
    ];
  }

  function getOperatorWeighmanFields() {
    return [
      {
        field: (vehicle: Vehicle) => <DateTime date={vehicle.arrivalDate} />,
        title: <FormattedMessage id="arrivalDate" />,
        icon: (
          <Icon
            onClick={onSortVehicles}
            name={sort && sort.direction === 'ASC' ? 'UpArrow' : 'DownArrow'}
            size="20px"
            style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          />
        ),
      },
      {
        field: 'vehiclePlateNumber',
        title: <FormattedMessage id="Vehicle" />,
      },

      {
        field: 'trailerPlateNumber',
        title: <FormattedMessage id="Trailer" />,
      },
      {
        field: (vehicle: Vehicle) => (
          <FormattedMessage id={vehicle.arrivalPurpose} />
        ),
        title: <FormattedMessage id="Purpose" />,
      },
      {
        field: (vehicle: Vehicle) => vehicle.grossWeight,
        title: <FormattedMessage id="grossWeight" />,
      },
    ];
  }

  function getWeighmanFields() {
    return [
      {
        field: (vehicle: Vehicle) => <DateTime date={vehicle.arrivalDate} />,
        title: <FormattedMessage id="arrivalDate" />,
        icon: (
          <Icon
            onClick={onSortVehicles}
            name={sort && sort.direction === 'ASC' ? 'UpArrow' : 'DownArrow'}
            size="20px"
            style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          />
        ),
      },
      {
        field: 'vehiclePlateNumber',
        title: <FormattedMessage id="Vehicle" />,
      },
      {
        field: 'trailerPlateNumber',
        title: <FormattedMessage id="Trailer" />,
      },
      {
        field: (vehicle: Vehicle) => (
          <FormattedMessage id={vehicle.arrivalPurpose} />
        ),
        title: <FormattedMessage id="Purpose" />,
      },
      {
        field: (vehicle: Vehicle) => vehicle.grossWeight,
        title: <FormattedMessage id="grossWeight" />,
      },
    ];
  }
}
